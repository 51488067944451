@import './global.scss';

.nav-links a {
  color: $offwhite;
}

.top-header {
  position: sticky;
  top:0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 0;
  @include flex($alignX:space-between);
  transition: all 1s ease;

  &:hover {
    transition: all .3s ease;
  }

  .logo {
    margin: $padding;
    @include mqTo($portrait){
      max-width: 40px;
      margin: $padding/2 $padding;
    }
  }

  >* {
    z-index: 100;
  }

  @include mqTo($portrait){
    top: auto;
    bottom: 0;
    background: rgba($offblack,.8);
    background-image: linear-gradient(to right, rgba($yellow,.8) , rgba($blue,.8), rgba($pink,.8));  
    background-size: contain;
    position: fixed;
    height: auto;
  }
}

.menu-toggle {
  width: 42px;
  height: 30px;
  margin:$padding;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &:before {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 150px;
    content: "";
    background: url("../img/menu.svg") repeat-x;
    background-size: contain;

  }

  &:hover {
    cursor: pointer;
  }

  @include mqTo($portrait){
    width: 32px;
    height: 20px;
    margin: $padding/2 $padding;

  }

  .menu-open & {
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before, &:after {
      content: "";
      width: 100%;
      height: 5px;
      @include absFill;
      background: $offblack;
      transform-origin: center;
      margin: auto;
    }
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
}

.menu {
  @include flex($dir:column);
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  background-image: linear-gradient(to right, $yellow , $blue, $pink);  
  background-size: contain;
  z-index: 90;
  transform: scale(2);
  opacity: 0;
  pointer-events: none;
  padding: 2rem;
  transition: all .6s $bezier;
  transition-delay: 0s;

  @include mqFrom($portrait){
    padding: 4rem;
  }


  a {
    font-size: $font-size--large;
    @include fontSize($size: $font-size--large,$decrease:.45);

    text-decoration: none;
    color: $offwhite;
    padding: .5rem;
    cursor: pointer;
    
    &:hover {
      color: $offblack;
    }
  }

  .menu-open & {
    transform: none;
    pointer-events: all;
    opacity: 1;
    transition-delay: .3s;

  }
}
