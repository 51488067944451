@import 'global.scss';

.page-footer {


  @include mqTo($portrait){
    
  }

  header {
    @include flex();
  }

  .footer-title {
    font-size: $font-size--small !important;
  }

  nav {
    margin-right: $margin;
    color: $offwhite;

    a {
      position: relative;
      z-index: 8;
      color: $offwhite;
      text-decoration: none;    
      font-size: $font-size--small !important;
  
    }

    a:hover {
      text-decoration: underline;
      cursor:pointer;
    }
  }

  h3 {
    font-size: $font-size--normal;
    color: $offwhite;
    margin-bottom: $margin;
  }

  .logo-wrapper {
    margin: $margin auto;
    @include mqFrom($portrait){
      margin: -10vw 0 -5vw;
    }
  }

  .logo {
    max-width: 35vw;
    min-width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0%;
    position: relative;
  }

  @media only screen and (max-width: 560px) {
    /*.logo-wrapper {
        display:none    
    }*/

    .container .inner {
      padding: 0;
    }

  }

}

.blueBG {
    background-color: $pink;
    width: 100%;
    padding: 0;
}


/*.footer {
    margin-left: 20%;
    margin-right: 20%;
    max-width: 90%;
    text-align: center;
}

.left{
    max-width: 30%;
    text-align:left;
    float:left;
}

.right{
    max-width: 30%;
    float:right;
    text-align:right;
}
.centered{
    max-width: 30%;
    text-align:center;
}*/