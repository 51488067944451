@import 'global.scss';
@import 'variables.scss';

.ledare {
    text-align: center;
    max-width: 60%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    
}

@media only screen and (max-width: $portrait) {
    .ledare {
        text-align: left;
        max-width: 100%;
        padding: 0 $padding;
        margin: 0;
    }
}

.ledare div {
    padding: 8px;
}

#ledare h2 {
    text-align: center;
    margin: auto;
   
}

.yellow {
    color: $yellow;
}

.blue {
    color: $blue;
}

.pink {
    color: $pink;
}
