@import 'variables.scss';
@import 'global.scss';


.profileImg {
    width: 200px;
    height: 200px;
    margin: 20px;
}

.pinkBorder {
    border: solid 5px $pink;
}

.yellowBorder {
    border: solid 5px $yellow;
}

.blueBorder {
    border: solid 5px $blue;
}

.profileImgCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.ledarImage {
    max-width: 20%;
    margin: 10px;
}

@media only screen and (max-width: $portrait) {
    .ledarImage {
        max-width: 26%;
    }
}
