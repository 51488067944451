
@import 'variables.scss';
@import 'mixins.scss';
@import 'columns.scss';

* {
    box-sizing: border-box;

}

html,body {
  font-size: $font-size;
  font-family: $font;
  background-color: $offwhite;
  color: $offblack;

  @include mqTo($portrait){
    overflow-x: hidden;
    overflow-y: visible;
  }
}

img {
    vertical-align: bottom;
}

  h1, h2, h3, h4, h5 {
    margin-top: .2rem;
    margin-bottom:.3rem;
    width: 100%;
    line-height: 1.2;
  }
  
  h2 {
    font-size: $font-size--medium;
    @include fontSize($size: $font-size--medium, $decrease:.30); //BUILD ERR
    //@include fontSize($size: $font-size--medium, $decrease:.30);

  }
  h3 {
    font-size: $font-size--normal;
    @include fontSize($size: $font-size--normal, $decrease:.30);
  }

  #about {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  

  p {
    line-height: 1.3;
    margin-top:0;
    margin-bottom:.5rem;
    font-size: $font-size--normal;
    @include fontSize($size: $font-size--normal, $decrease:.30);
  }

//Button
.btnCont {
    text-align: center;
}

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: solid 3px $offblack;
    font-weight: bold;
    width: 200px;
    height: 60px;
    background-color: $offwhite;
    margin-top: -1rem;
    font-size: 1.5rem;

    //border-radius: 12px;
}  

.btn:hover {
    border: solid 3px $offblack;
    background-color: $offblack;
    color: $offwhite;
    cursor: pointer;
}

.btn-text {
    font-size: $font-size--mini;
    margin-top: 1rem;
}
//--------

.logo {
    position: absolute;
    top: 30%;
    left: 30%;
    right: 30%;
    bottom: 30%;
    width: 40%;
    text-align: center;
    margin: auto;
}

.container, .inner {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  
  .container {
    // max-width: $max-width;
    @include maxWidth($max-width);
  }
  
  .inner {
    // max-width: $max-width-inner;
    padding: $padding;
}

a {
  color: $offblack;
}

a:not(.btn) {
  text-decoration: underline;
}

.flex{
    &-row {
      @include flex();
    }
    &-col {
      @include flex($dir:column, $alignX:flex-start);
    }
  
    &--spread {
      justify-content: space-between;
    }
    &--stretch {
      justify-content: stretch;
    }
    &--start {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  .block {
    padding: $margin 0;
    width: 100%;
  
    @include mqFrom($portrait){
      padding: $blockPadding 0;
    }
  }