@import "global.scss";

.yellowBG {
    background-color: $yellow;
}

#tips {
    padding-top: $padding;
    padding-bottom: $padding;
    margin-bottom: $margin;
    margin-top: $margin;
}
