@import './variables.scss';
@import "global.scss";


.hero-image{ //container
  position: relative;

}

.bg-img { //video
    height: 100vh;
    min-width: 100%;
    object-fit: cover;
  }


.hero {
  @include flex($dir:column);

  &-menu {

    z-index: 10;
    padding: $padding;
    top:0;
    position: absolute;

    a {
      padding: .3rem $padding;
      color: $offwhite;
      text-decoration:none
    }

    a:hover {
      text-decoration: underline;
    }

    @include mqTo($portrait - 1){
      display: none;
    }
  }

  h1 span {
    display: block;
  }

    min-height: 100vh;


  .animated-letters {
    z-index: 2;
  }



  &-image {
    position: relative;
    //height: 100vh;
    min-width: 100vw;
    //background-size: contain;
    position: absolute;
    bottom: 0;
    top:0;
    left: 0;
    pointer-events: none;


    img {
      position: relative;
      z-index: 0;
    }

    &--blue {

      &:after {
        background: $pink;
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        z-index: 1;
        background-blend-mode: multiply;

      }
    }

    @include mqFrom($portrait){
      position: absolute;
      bottom: 0;
      top:0;
      left: 0;
      &--half {
        position: relative;

        width: 50%;
        flex:1;
      }
    }
  }

  .logo-wrapper {
    margin-bottom: $padding;
    max-width: 60vw;
    margin: auto;

    @include mqFrom($portrait){
      max-width: 40vw;
    }
  }

  .text-content {
    z-index: 2;
    padding: 0;
    text-align: center;
    width: 100%;
    @include mqFrom($portrait){
      // padding: $blockPadding 0 $padding;

    }

    &--block {
      background: $pink;
      @include mqFrom($portrait){
        width: 50%;
        flex:1;
        order:-1;
      }
    }
  }
}

@keyframes changeText {
  from, 60%, to {
    // filter: blur(20px);
    transform: scale(.9);
    transition: all .6s $bezier;
    opacity: 0;

  }
  10%,50% {
    transform: none;
    filter: none;
    opacity: 1;
  }
}

/*@keyframes changeText {
  from, 60%, to {
    filter: blur(20px);
    transform: scale(.9);
    transition: all .6s $bezier;
    opacity: 0;

  }
  from {
    transform: translateY(3rem);
  }
  10%,50% {
    transform: none;
    filter: none;
    opacity: 1;
  }
  60%, to {
    transform: translateY(-3rem);
  }
}*/

$dur:10s;

.change-text {
  position: relative;

  &__content {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

