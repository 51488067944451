//Breakpoints
$portrait:768px;
$landscape:1024px;
$desktop:1100px;
$xl:1350px;

// Colors 
$offwhite: #F6ECE1;
$offblack: #3C2F2D;
$pink: #EF8381;
$blue: #84A59C;
$yellow: #F6BC5F;
$red: #f45932;

// Font styles
$font-size:16px;
$font: "Poppins", sans-serif;

$line-height:1.5;
$line-height-heading:1.0;
$font-size--mini:.9rem;
$font-size--small:.95rem;
$font-size--normal:1.5rem;
$font-size--medium:1.8rem;
$font-size--large:4rem;

// Transitions
$bezier: cubic-bezier(.51,.14,.29,1);

// Distances
$gutter:3rem;
$padding: 3rem;
$margin: 3rem;
$blockPadding: 0;


// Max widths
$max-width:1100px;
$max-width-inner:900px;
$max-width-text:650px;
$max-width-text:750px;
$max-width-header:65vw;
$max-width-article:750px;