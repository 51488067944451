@import 'global';

/*.photographer {
  display: block;
  padding: 0 $padding;
  width: 100%;
  text-align: right;
  margin-top: -1rem;
}

.images {
  margin-left: 1rem;
  margin-right: 1rem;

  @include mqFrom($portrait){
    margin-left: 1rem;
    margin-right: 1rem;
  }

  figure {
    margin: 0;
    max-width: 50%;
    // @include responsive-ratio(16,9,true);
    img {
      padding: 1rem;
      max-width: 100%;
      height: auto;
      z-index:1;

      @include mqFrom($portrait){
        height: auto;
        //padding: $padding;

      }
    }

  }*/

  /*.flex-row {
    position: relative;

    flex-wrap: wrap;

   // figure {
      // flex: 1;
    //}
  }
}*/


/*.image {
  max-width: 100%;
  //padding: 2px;
}

.images {
  text-align: center;
  margin: auto;
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: center;
}*/

.imageBlock {
  background-color: $blue;
  padding-top: $padding !important;
  padding-bottom: $padding !important;
  margin-bottom: $margin;    
  margin-top: $margin;
}

.imageText {
  font-size: 8px;
}

.img-text {
  font-size: $font-size--mini !important;
  font-style: italic;
  padding-top: 0px !important;
  margin: 0px !important;
  color: $offwhite !important;
  text-align: center;
}

ul {
  display: flex;
  flex-wrap: wrap;
}

li {
  height: 40vh;
  flex-grow: 1;
  list-style: none;
  padding-top: 1.5rem;
}

li:last-child {
  flex-grow: 10;
}

.images img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
